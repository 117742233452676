import { render, staticRenderFns } from "./Memorabilia.vue?vue&type=template&id=ba1a109a&scoped=true&"
var script = {}
import style0 from "./Memorabilia.vue?vue&type=style&index=0&id=ba1a109a&lang=less&scoped=true&"
import style1 from "./Memorabilia.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba1a109a",
  null
  
)

export default component.exports